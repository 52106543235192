import { ApolloQueryResult } from '@apollo/client';
import {
	Language,
	News,
	NewsQueries,
	PaginationByPage,
	SportId,
} from '@ui-kit/main-api-types';

import { client } from '@apollo-client-v2';
import { useUserStore } from '@store/user';

import mutations from './mutations';
import queries from './queries';

export const getNextNewsForArticle = async ({
	id,
	sportType,
	locale,
	appName,
}: {
	id: string;
	sportType: string[];
	locale: string;
	appName: string;
}): Promise<News> => {
	const { authToken } = useUserStore.getState().user;
	let nextNewsResponse: ApolloQueryResult<{ newsQueries: NewsQueries }> = null;

	try {
		nextNewsResponse = await client.query({
			query: queries.getNextNewsForArticle,
			variables: {
				id,
				lang: locale.toUpperCase(),
				sportType,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase() || 'en',
					'x-tribuna-app': appName || 'football',
					'x-auth-token': authToken,
				},
			},
		});
	} catch (err) {
		console.error(err);
	}
	const nextNews: News = nextNewsResponse?.data?.newsQueries?.feed?.items?.[0];

	if (!nextNews?.link) {
		return nextNews;
	} else {
		return getNextNewsForArticle({
			id: `${nextNews?.publicationTime}`,
			locale: locale.toUpperCase(),
			sportType,
			appName,
		});
	}
};

export const getNewsByHru = async ({
	id,
	locale,
	appName,
}: {
	id: string;
	locale: string;
	appName: string;
}): Promise<News> => {
	return client
		.query({
			query: queries.getNewsByHru,
			variables: {
				id,
				locale: locale.toLocaleUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase() || 'en',
					'x-tribuna-app': appName || 'football',
				},
			},
		})
		.then(res => res?.data?.newsQueries.get);
};

export const getLatestNewsList = async ({
	pageSize,
	locale,
	appName,
}: {
	pageSize: number;
	locale: string;
	appName: string;
}): Promise<News[]> => {
	return client
		.query({
			query: queries.getLatestNewsList,
			variables: {
				page: 1,
				pageSize,
				lang: locale?.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase() || 'en',
					'x-tribuna-app': appName || 'football',
				},
			},
			errorPolicy: 'all',
		})
		.then(res => res?.data?.newsQueries?.feed?.items);
};

export const getNewsForMainPage = async ({
	pageSize = 50,
	locale,
	page = 1,
	isMain,
}: {
	pageSize?: number;
	locale: string;
	page?: number;
	isMain?: boolean;
}) => {
	return client
		.query({
			query: queries.getNewsForMainPage,
			variables: {
				page,
				pageSize,
				lang: locale?.toUpperCase(),
				isMain,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase() || 'en',
				},
			},
		})
		.then(res => ({
			newsList: res?.data?.newsQueries?.feed?.items,
			paginationInfo: res?.data?.newsQueries?.feed?.paginationInfo,
		}));
};

interface IGetNews {
	locale: string;
	page: number;
	pageSize: number;
	lang?: Language;
	isMain?: boolean;
	sportType?: SportId | SportId[];
	isSeoContent?: boolean;
	isRegularContent?: boolean;
	isMainInSection?: boolean;
}

export const getNewsList = async ({
	locale,
	page,
	pageSize,
	lang,
	isMain,
	isSeoContent,
	isRegularContent,
	isMainInSection,
	sportType,
}: IGetNews): Promise<{
	newsList: News[];
	paginationInfo: PaginationByPage;
}> => {
	const newsQueryResult: ApolloQueryResult<{
		newsQueries: NewsQueries;
	}> = await client.query({
		query: queries.getNewsForMainPage,
		variables: {
			page,
			pageSize,
			lang,
			isMain,
			isSeoContent,
			isRegularContent,
			isMainInSection,
			sportType,
		},
		context: {
			headers: {
				'X-Language': locale?.toLowerCase() || 'en',
			},
		},
	});

	return {
		newsList: newsQueryResult?.data?.newsQueries?.feed?.items,
		paginationInfo: newsQueryResult?.data?.newsQueries?.feed
			?.paginationInfo as PaginationByPage,
	};
};

export const deleteNews = newsId => {
	const user = useUserStore.getState().user;
	const authToken = user?.authToken;
	return client.mutate({
		mutation: mutations.deleteNews,
		variables: {
			newsId,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};
