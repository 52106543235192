import { gql } from '@apollo/client';

export default {
	deleteNews: gql`
		mutation DeleteNews($newsId: ID!) {
			newsMutations {
				delete(input: { id: $newsId }) {
					id
				}
			}
		}
	`,
};
