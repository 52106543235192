import { gql } from '@apollo/client';

import {
	LOCALIZED_SB_FRAGMENT,
	LOCALIZED_SHORT_SB_FRAGMENT,
	LOCALIZED_STRING_FRAGMENT,
	TAGS_QUERIES_ARTICLE,
} from '@store/fragments';

const EXTRA_TEAM_FRAGMENT = gql`
	fragment tagTeamExtra on TagTeamExtra {
		interfaceTitle {
			...localizedString
		}
	}
`;

const EXTRA_OTHER_FRAGMENT = gql`
	fragment tagOtherExtra on TagOtherExtra {
		interfaceTitle {
			...localizedString
		}
	}
`;

const EXTRA_PERSON_FRAGMENT = gql`
	fragment tagPersonExtra on TagPersonExtra {
		firstName {
			...localizedString
		}
		lastName {
			...localizedString
		}
	}
`;

const COMMENT_FRAGMENT = gql`
	fragment CommentFragmentNews on Comment {
		id
		application
		text
		created
		objectId
		edited
		isDeleted
		isReplyToTopLevelComment
		user {
			name
			avatar {
				webp(width: 80, height: 80, quality: 80)
			}
			id
			roles {
				ID
			}
		}
		objectClass
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		threadCommentsCount
		childThread(
			input: { sort: OLDEST, pagination: { ByCursor: { first: 10 } } }
		) {
			items {
				id
				application
				text
				created
				threadId
				isDeleted
				edited
				isReplyToTopLevelComment
				objectClass
				objectId
				user {
					id
					name
					avatar {
						webp(width: 80, height: 80, quality: 80)
					}
					roles {
						ID
					}
				}
				reactions {
					userReaction {
						objectClass
						objectID
						reactionType
					}
					likeCount
					dislikeCount
				}
				parentComment {
					id
					isDeleted
					user {
						id
						name
					}
				}
				threadComment {
					id
					user {
						id
						name
					}
				}
			}
			paginationInfo {
				... on PaginationByCursor {
					totalItemsCount
					endCursor
					hasNextPage
				}
			}
		}
	}
`;

const NEWS_AUTHOR_FRAGMENT = gql`
	fragment newsAuthor on Author {
		user {
			id
			name
			nickname
			avatar {
				url
			}
			createTime
		}
	}
`;

export default {
	getNextNewsForArticle: gql`
		${COMMENT_FRAGMENT},
		${TAGS_QUERIES_ARTICLE},
		${LOCALIZED_SB_FRAGMENT},
		${LOCALIZED_STRING_FRAGMENT}
		${EXTRA_TEAM_FRAGMENT}
		${EXTRA_OTHER_FRAGMENT}
		${EXTRA_PERSON_FRAGMENT}
		${NEWS_AUTHOR_FRAGMENT}
		query getNextNewsForArticleQuery(
			$id: String,
			$lang: Language,
			$sportType: [SportID!],
			$isMainInSection: Boolean
			$status: DocumentStatus = PUBLISHED
		) {
			newsQueries {
				feed(input: {
					language: $lang,
					pagination: {ByCursor: {first: 1, after: $id}}
					isMainInSection: $isMainInSection,
					sport: $sportType,
					status: $status,
				}) {
					items {
						id
						hru
						tags {
							...TagsQueryFragmentForArticle
						}
						structuredBody {
							...LocalizedSB
						}
						commentsDisabled
						reactions {
							userReaction {
								objectClass
								objectID
								reactionType
							}
							likeCount
							dislikeCount
						}
						matches {
							id
							hru
							matchStatus
							scheduledAt
							hadPenalties
							matchStatus
							tabs(lang: $lang) {	
								hru	
								title
								type
							}
							venue {
								name
								tag {
									id
									hru
									title {
										...localizedString
									}
									extra {
										...tagOtherExtra
									}
								}
							}
							season {
								tournament {
									tag {
										id
										hru
										type
									}
								}
							}
							events {
								type
								value {
									...on statYellowCard {
										matchTime
										stoppageMin
										player {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													...tagOtherExtra
												}
											}
										}
									}
									...on statYellowRedCard {
										matchTime
										stoppageMin
										player {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													...tagOtherExtra
												}
											}
										}
									}
									...on statRedCard {
										matchTime
										stoppageMin
										player {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													...tagOtherExtra
												}
											}
										}
									}
									...on statScoreChange {
										matchTime
										stoppageMin
										homeScore
										awayScore
										periodType
										methodScore
										goalScorer {
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													...tagOtherExtra
												}
											}
										}
										assist {
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													...tagOtherExtra
												}
											}
										}
									}
									...on statPenaltyShootout {
										homePenaltyScore
										awayPenaltyScore
										status
										team
									}
								}
							}
							home {
								score
								lineup {
									lineupStarting
									substitutions {
										matchTime
										stoppageMin
										playerOut {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													... tagPersonExtra
												}
											}
										}
										playerIn {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													... tagPersonExtra
												}
											}
										}
									}
									player {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
								}
								team {
									tag {
										type
										title {
											...localizedString
										}
										extra {
											...tagTeamExtra
										}
									}
								}
							}
							away {
								score
								lineup {
									lineupStarting
									substitutions {
										matchTime
										stoppageMin
										playerOut {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													... tagPersonExtra
												}
											}
										}
										playerIn {
											id
											coalesceName(names: ["lastName"])
											tag {
												id
												hru
												type
												title {
													...localizedString
												}
												extra {
													... tagPersonExtra
												}
											}
										}
									}
									player {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
								}
								team {
									tag {
										type
										title {
											...localizedString
										}
										extra {
											...tagTeamExtra
										}
									}
								}
							}
							periodScore {
								homeScore
								awayScore
								number
								type
							}
						}
						structuredDescription {
							...LocalizedSB
						}
						title  {
							...localizedString
						}
						commentsCount
						topLevelComments(input: {sort: BEST, pagination: { ByCursor: { first: 3 } }} ) {
							items {
								...CommentFragmentNews
							}
							paginationInfo {
								... on PaginationByCursor {
									totalItemsCount
									hasNextPage
									endCursor
								}
							}
						}
						link
						author {
							...newsAuthor
						}
						publicationTime
						source {
							name
							url
						}
						mainSport {
							id
							path
							name {
								...localizedString
							}
						}
						mainPhoto {
							url
							meta{
								width
								height
							}
						}
						languages
						shareInfo {
							title
							description
						}
						isAdvertisement
						sports {
							id
							path
							name {
								...localizedString
							}
						}
					}
					paginationInfo {
						...on PaginationByCursor {
							endCursor
						}
					}
				}
			}
		}
	`,
	getNewsByHru: gql`
		${COMMENT_FRAGMENT},
		${TAGS_QUERIES_ARTICLE},
		${LOCALIZED_SB_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		${EXTRA_TEAM_FRAGMENT}
		${EXTRA_OTHER_FRAGMENT}
		${EXTRA_PERSON_FRAGMENT}
		${NEWS_AUTHOR_FRAGMENT}
		query newsByHruQuery($id: ID!, $locale: Language ) {
			newsQueries {
				get(input: {id: $id}) {
					id
					hru
					label
					link
					tags {
						...TagsQueryFragmentForArticle
					}
					structuredBody {
						...LocalizedSB
					}
					commentsDisabled
					reactions {
						userReaction {
							objectClass
							objectID
							reactionType
						}
						likeCount
						dislikeCount
					}
					matches {
						id
						hru
						matchStatus
						scheduledAt
						hadPenalties
						matchStatus
						tabs(lang: $locale) {	
							hru	
							title
							type
						}
						season {
							tournament {
								tag {
									id
									hru
									type
								}
							}
						}
						venue {
							name
							tag {
								id
								hru
								title {
									...localizedString
								}
								extra {
									...tagOtherExtra
								}
								type
							}
						}
						events {
							type
							value {
								...on statYellowCard {
									matchTime
									stoppageMin
									player {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												...tagOtherExtra
											}
										}
									}
								}
								...on statYellowRedCard {
									matchTime
									stoppageMin
									player {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												...tagOtherExtra
											}
										}
									}
								}
								...on statRedCard {
									matchTime
									stoppageMin
									player {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												...tagOtherExtra
											}
										}
									}
								}
								...on statScoreChange {
									matchTime
									stoppageMin
									homeScore
									awayScore
									periodType
									methodScore
									goalScorer {
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												...tagOtherExtra
											}
										}
									}
									assist {
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												...tagOtherExtra
											}
										}
									}
								}
								...on statPenaltyShootout {
									homePenaltyScore
									awayPenaltyScore
									status
									team
								}
							}
						}
						home {
							score
							lineup {
								lineupStarting
								substitutions {
									matchTime
									stoppageMin
									playerOut {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
									playerIn {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
								}
								player {
									id
									coalesceName(names: ["lastName"])
									tag {
										id
										hru
										type
										title {
											...localizedString
										}
										extra {
											... tagPersonExtra
										}
									}
								}
							}
							team {
								tag {
									type
									title {
										...localizedString
									}
									extra {
										...tagTeamExtra
									}
								}
							}
						}
						away {
							score
							lineup {
								lineupStarting
								substitutions {
									matchTime
									stoppageMin
									playerOut {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
									playerIn {
										id
										coalesceName(names: ["lastName"])
										tag {
											id
											hru
											type
											title {
												...localizedString
											}
											extra {
												... tagPersonExtra
											}
										}
									}
								}
								player {
									coalesceName(names: ["lastName"])
									id
									tag {
										id
										hru
										type
										title {
											...localizedString
										}
										extra {
											... tagPersonExtra
										}
									}
								}
							}
							team {
								tag {
									type
									title {
										...localizedString
									}
									extra {
										...tagTeamExtra
									}
								}
							}
						}
						periodScore {
							homeScore
							awayScore
							number
							type
						}
					}
					structuredDescription {
						...LocalizedSB
					}
					title {
						...localizedString
					}
					commentsCount
					topLevelComments(input: {sort: BEST, pagination: { ByCursor: { first: 3 } }} ) {
						items {
							...CommentFragmentNews
						}
						paginationInfo {
							... on PaginationByCursor {
								totalItemsCount
								hasNextPage
								endCursor
							}
						}
					}
					languages
					link
					author {
						...newsAuthor
					}
					displayAuthor {
						...newsAuthor
					}
					publicationTime
					modifiedTime
					source {
						name
						url
					}
					mainPhoto {
						url
						meta {
							height
							width
						} 
					}
					shareInfo {
						title 
						description
						picture {
						  url
						  meta {
							height
							width
						  }
						}
					}
					mainSport {
						id
						path
						name {
							...localizedString
						}
					}
					sports {
						id
						path
						name {
							...localizedString
						}
					}
					seoNew {
						title
						canonical
						description
					}
				}
			}
		}
	`,
	getServiceNews: gql`
		${LOCALIZED_SHORT_SB_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		query newsByHruQuery($id: ID!) {
			newsQueries {
				get(input: { id: $id }) {
					id
					hru
					structuredBody {
						...LocalizedShortSB
					}
					shareInfo {
						title
						description
						picture {
							url
							meta {
								height
								width
							}
						}
					}
					seoTitle {
						...localizedString
					}
					seoDescription {
						...localizedString
					}
					seoCanonical {
						...localizedString
					}
				}
			}
		}
	`,
	getLatestNewsList: gql`
		${LOCALIZED_STRING_FRAGMENT}
		query latestNewsList(
			$page: Int = 1
			$pageSize: Int = 6
			$sportType: [SportID!]
			$lang: Language
			$status: DocumentStatus = PUBLISHED
		) {
			newsQueries {
				feed(
					input: {
						status: $status
						sport: $sportType
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $lang
					}
				) {
					items {
						id
						link
						hru
						title {
							...localizedString
						}
						commentsCount
						author {
							alias
						}
						isMain
						isMainSection
						label
						publicationTime
						source {
							url
						}
						mainPhoto {
							url
						}
						mainSport {
							id
							path
							name {
								...localizedString
							}
						}
						label
						mainSport {
							id
							path
							name {
								...localizedString
							}
						}
					}
					paginationInfo {
						... on PaginationByPage {
							lastPage
						}
					}
				}
			}
		}
	`,
	getNewsForMainPage: gql`
		${LOCALIZED_STRING_FRAGMENT}
		query newsForMainPageQuery(
			$page: Int = 1
			$pageSize: Int = 40
			$sportType: [SportID!]
			$lang: Language
			$isMain: Boolean
			$isMainInSection: Boolean
			$status: DocumentStatus = PUBLISHED
			$isRegularContent: Boolean
			$isSeoContent: Boolean
		) {
			newsQueries {
				feed(
					input: {
						status: $status
						isMain: $isMain
						isMainInSection: $isMainInSection
						isRegularContent: $isRegularContent
						isSeoContent: $isSeoContent
						sport: $sportType
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $lang
					}
				) {
					items {
						id
						link
						hru
						title {
							...localizedString
							defaultValue(language: $lang)
						}
						commentsCount
						author {
							alias
							user {
								id
								nickname
								name
							}
						}
						isMain
						isMainSection
						label
						publicationTime
						source {
							url
						}
						mainPhoto {
							url
						}
						mainSport {
							id
							path
							name {
								...localizedString
								defaultValue(language: $lang)
							}
						}
					}
					paginationInfo {
						... on PaginationByPage {
							lastPage
							currentPage
						}
					}
				}
			}
		}
	`,
};
